<template>
  <div>
    <v-menu
      v-model="showMenu"
      :disabled="menuItems.length <= 1"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-container
          v-if="getTitleFromPath"
        >
          <v-row>
            <v-col>
              <v-subheader
                class="text-subtitle-2 text-lg-subtitle-1 font-weight-medium"
                v-bind="attrs"
                v-on="on"
                v-ripple
              >
                {{ getTitleFromPath }}
                <v-icon
                v-show="menuItems.length > 1"
                  class="ml-2"
                >
                  mdi-chevron-down
                </v-icon>
              </v-subheader>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="goToSelectedRoute(item.path)"
        >
          <v-list-item-title
            :class="item.path === $route.path ? 'highlighted' : ''"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    showMenu: false,
  }),

  methods: {
    goToSelectedRoute(path) {
      this.$router.push({ path });
    },
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),

    menuItems() {
      const menuItems = [];
      /*
        This is a dirty hack to make the local/regional independent working for
        certain customers. We abuse the 'MC11' module for this. In the future
        we might to do this more structured.
        Also we made sure that in the router we take care what happens when a
        user has no active modules
      */
      if (this.authHasModuleAccess('MC11')) menuItems.push({ title: 'NL dashboard', path: '/dashboard' });
      if (this.authHasModuleAccess('MC07')) menuItems.push({ title: 'EU dashboard', path: '/eu/dashboard' });
      if (this.authHasModuleAccess('MC13')) menuItems.push({ title: 'Provincie & Gemeente dashboard', path: '/nl-lr/dashboard' });
      if (this.authHasModuleAccess('MC05')) menuItems.push({ title: 'Agenda dashboard', path: '/agenda' });
      if (this.authHasModuleAccess('MC04')) menuItems.push({ title: 'Opgeslagen lijsten', path: '/list-manager' });
      return menuItems;
    },

    getTitleFromPath() {
      const { title } = this.menuItems.find((item) => item.path === this.$route.path) || {};
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.highlighted {
  color: #00897B;
}
</style>
