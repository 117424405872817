<template>
  <div>
    <div
      v-if="emailPreferences"
    >
      <v-form
        ref="preferencesForm"
        v-model="valid"
        valid
      >
        <v-card class="mb-4 mt-5">
          <v-card-title class="text-subtitle-1">Algemeen</v-card-title>
          <v-card-text>
            <v-checkbox
            class="mt-n2"
            v-model="emailPreferences.emailOnNoHits"
            label="Ontvang een e-mail als er geen resultaten gevonden zijn"
            />
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title class="text-subtitle-1">E-mailtijden</v-card-title>
          <v-card-text>
            <div>
              Ontvang de <strong>dagelijkse</strong> e-mail iedere dag om
              <v-select
                v-model="dailyEmailTime"
                class="time"
                :items="possibleEmailTimes"
                dense
              ></v-select>
              uur.
            </div>
            <div
              v-if="authHasModuleAccess('MC08')"
            >
              Ontvang de <strong>wekelijkse</strong> e-mail iedere
              <!-- v-if required if in invalid response is given-->
              <v-select
                v-if="emailPreferences.weeklyEmail"
                v-model="emailPreferences.weeklyEmail.day"
                class="weekday"
                :items="weekdays"
                item-text="label"
                item-value="value"
                dense
              ></v-select>
              om
              <v-select
                v-model="weeklyEmailTime"
                class="time"
                :items="possibleEmailTimes"
                dense
              ></v-select>
              uur.
            </div>
            <div class="text-caption mt-n2 pb-2 font-italic">
              *Let op! Gewijzigde e-mailtijden zijn actief vanaf de volgende dag.
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title class="text-subtitle-1">Vakantiemodus</v-card-title>
          <v-card-text>
            Met de vakantiemodus zet je alle e-mails tijdelijk stop.
            <div class="text-caption pt-2 font-italic">
              *Let op! Het is niet mogelijk de gemiste updates achteraf nog te ontvangen.
            </div>
            <v-switch
              class="mt-2"
              v-model="vacationModeToggle"
              label="Aan"
            />
            <div
              v-if="vacationModeToggle"
              class="vacation-mode-end-date-box"
            >
              <div :class="this.$vuetify.theme.dark ? 'line light' : 'line dark'"/>
              Zet automatisch uit na
              <v-text-field
                :value="vacationModeEndDateFormatted"
                placeholder="(datum)"
                append-icon="mdi-calendar-range"
                dense
                readonly
                @click="datePickerModal = true"
                :error-messages="vacationModeEndDateValidationMessages"
              />

              <v-dialog
                v-if="datePickerModal"
                v-model="datePickerModal"
                persistent
                width="290px"
              >
                <v-date-picker
                  ref="datePicker"
                  v-model="datePickerDate"
                  no-title
                  locale="nl-nl"
                  :min="minVacationModeEndDate"
                  :max="maxVacationModeEndDate"
                >
                  <div class="explanation text-caption pb-2 font-italic">
                    *De einddatum mag maximaal 31 dagen in de toekomst liggen.
                  </div>
                  <div class="buttons">
                    <v-btn
                      color=""
                      @click="datePickerModal = false"
                    >
                      Annuleer
                    </v-btn>
                    <v-btn
                      color="teal
                      lighten-1"
                      class="white--text"
                      @click="datePickerModal = false; setVacationModeEndDate();"
                    >
                      OK
                    </v-btn>
                  </div>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-card-text>
        </v-card>

        <v-card
          v-if="emailTemplateOptions && emailPreferences.templates"
          class="mb-4"
        >
          <v-card-title class="text-subtitle-1">E-mailtemplate</v-card-title>
          <v-card-text>
            <v-row class="pr-4">
              <v-col
                class="d-flex"
                cols="12"
                md="6"
              >
                <v-select
                  class="template-toggle"
                  v-model="directEmailTemplate"
                  :items="directOptions"
                  label="Direct"
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                md="6"
              >
                <v-select
                  class="template-toggle"
                  v-model="periodicEmailTemplate"
                  :items="periodicOptions"
                  item-value="type"
                  item-text="name"
                  label="Dagelijks/wekelijks"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col>
                <div class="explanation text-caption font-italic">
                  *In het basis e-mailtemplate ontbreekt nieuwe functionaliteit. We raden dit template alleen aan als je technische problemen ondervindt met het uitgebreide template.
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-form>
      <v-card class="mb-4">
        <account-preferences-form-email-saved-searches
          v-on:update-save-status="passthroughEvent"
        />
      </v-card>
    </div>
    <div
      v-if="!emailPreferences"
    >
      De email instellingen kunnen niet worden geladen.
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import { mapGetters } from 'vuex';
import AccountPreferencesFormEmailSavedSearches from '@/components/AccountPreferencesFormEmailSavedSearches.vue';

export default {
  name: 'AccountPreferencesFormEmail',

  components: {
    AccountPreferencesFormEmailSavedSearches,
  },

  props: [
    'emailPreferences',
    'emailTemplateOptions',
  ],

  async mounted() {
    // Dynamically create all possible email times
    // That is 5:00 -> 22:00 currently
    const possibleEmailTimes = [];
    const hourRange = [...Array(18).keys()].map((i) => i + 5);
    hourRange.forEach((hour) => {
      possibleEmailTimes.push(`${hour}:00`, `${hour}:30`);
    });
    this.possibleEmailTimes = possibleEmailTimes.slice(0, -1); // Remove the very last one (22:30)

    // Set inital values for vacationMode variables
    const { vacationModeEndDate } = this.emailPreferences;
    if (vacationModeEndDate && (new Date(vacationModeEndDate) <= new Date())) {
      // The endDate has already passed, meaning vacation mode is not longer truely 'on'
      this.emailPreferences.vacationModeEndDate = null;
    } else if (vacationModeEndDate) {
      this.vacationModeToggle = true;
    }

    // Set inital values for templates variable
    const { templates } = this.emailPreferences;
    await this.$set(this.emailPreferences, 'templates', templates || {
      direct: null,
      periodic: null,
    });

    // We wait before allowing any form submits
    // because we seem to somehow trigger the
    // `emailPreferences`-watcher after
    // this mounted function has already returned
    await new Promise((r) => setTimeout(r, 100));
    this.componentReady = true;
  },

  data() {
    return {
      componentReady: false,

      valid: false,

      vacationModeToggle: false,
      maxVacationModeEndDate: moment().add(31, 'days').startOf('day').format('YYYY-MM-DD'),
      minVacationModeEndDate: moment().add(1, 'day').startOf('day').format('YYYY-MM-DD'),

      datePickerModal: false,
      datePickerDate: null,
      vacationModeEndDateValidationMessages: [],

      possibleEmailTimes: [], // Dynamically filled in mounted()
      weekdays: [
        { label: 'maandag', value: 1 },
        { label: 'dinsdag', value: 2 },
        { label: 'woensdag', value: 3 },
        { label: 'donderdag', value: 4 },
        { label: 'vrijdag', value: 5 },
        { label: 'zaterdag', value: 6 },
        { label: 'zondag', value: 0 },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),
    vacationModeEndDateFormatted() {
      if (!this.emailPreferences.vacationModeEndDate) return null;
      return new Date(this.emailPreferences.vacationModeEndDate).toLocaleDateString('nl-NL');
    },
    dailyEmailTime: {
      get() { return this.getTime('dailyEmail'); },
      set(time) { this.setTime(time, 'dailyEmail'); },
    },
    weeklyEmailTime: {
      get() { return this.getTime('weeklyEmail'); },
      set(time) { this.setTime(time, 'weeklyEmail'); },
    },
    directOptions() {
      return Object.values(this.emailTemplateOptions.direct);
    },
    periodicOptions() {
      return Object.values(this.emailTemplateOptions.periodic);
    },
    directEmailTemplate: {
      get() { return this.getEmailTemplate('direct'); },
      set(version) { this.setEmailTemplate('direct', version); },
    },
    periodicEmailTemplate: {
      get() { return this.getEmailTemplate('periodic'); },
      set(version) { this.setEmailTemplate('periodic', version); },
    },
  },

  watch: {
    vacationModeToggle(value) {
      if (value) {
        // hide spinner/saved status to extra clarify nothing is yet saved
        // When only the toggle is turned on
        this.$emit('update-save-status', null, true);
      } else {
        // Turn off vacation mode
        this.emailPreferences.vacationModeEndDate = null;
      }
    },
    'emailPreferences.vacationModeEndDate': {
      handler(value) {
        this.vacationModeEndDateValidationMessages = value ? [] : ['Verplicht Veld'];
      },
      immediate: true, // Run on being mounted
    },
    emailPreferences: {
      handler: 'submitForm',
      deep: true,
    },
  },

  methods: {
    setVacationModeEndDate() {
      this.emailPreferences.vacationModeEndDate = moment(this.datePickerDate).endOf('day').toISOString();
    },
    getTime(type) {
      if (!this.emailPreferences[type]) return null;
      const emailTime = this.emailPreferences[type];
      return `${emailTime.hour}:${emailTime.minutes.toString().padStart(2, '0')}`;
    },
    setTime(time, type) {
      this.emailPreferences[type].hour = parseInt(time.split(':')[0], 10);
      this.emailPreferences[type].minutes = parseInt(time.split(':')[1], 10);
    },

    getEmailTemplate(type) {
      if (!this.emailPreferences.templates[type]) return null;
      const templateType = this.emailPreferences.templates[type];
      return this.emailTemplateOptions[type][templateType];
    },
    setEmailTemplate(type, version) {
      this.emailPreferences.templates[type] = Object.keys(this.emailTemplateOptions[type])
        .find((key) => this.emailTemplateOptions[type][key] === version);
    },

    async submitForm() {
      if (!this.componentReady) return;

      // show spinner
      this.$emit('update-save-status', 'loading');

      if (this.$refs.preferencesForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const success = await this.$store.dispatch('setEmailPreferences', this.emailPreferences);

        if (success && success.emailConfig) {
          this.$store.dispatch('setMessage', {
            message: 'Instellingen zijn succesvol opgeslagen',
            type: 'success',
          });
          // show saved
          this.$emit('update-save-status', 'saved');
        } else {
          this.$store.dispatch('setMessage', {
            message: 'Fout bij het opslaan van de instellingen',
            type: 'error',
          });

          // hide spinner
          this.$emit('update-save-status', null);
        }
      } else {
        // hide spinner
        this.$emit('update-save-status', null);
      }
    },
    passthroughEvent(value) {
      this.$emit('update-save-status', value);
    },
  },
};

</script>

<style scoped lang="scss">

.v-card__text {
  padding: 0px 0px 0px 16px;
}

.text-subtitle-1 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.v-select {
  display: inline-block;

  &.time {
    width: 6em;
  }

  &.weekday {
    width: 8em;
  }

  ::v-deep .v-select__selections {
    .v-select__selection {
      text-align: center;
      width: 100%;
    }
  }
}

.v-input--switch, ::v-deep .v-messages {
  min-height: 0;
}

.vacation-mode-end-date-box {
  .line {
    display: inline-block;
    width: 1.5em;
    height: 1em;
    margin: 3px 5px 3px 18px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;

    &.dark {
      border-color: rgba(0, 0, 0, 0.6);
    }

    &.light {
      border-color: rgba(255, 255, 255, 0.7);
    }
  }

  .v-text-field {
    display: inline-block;
    width: 10em;

    ::v-deep input {
      text-align: center;
    }
  }
}

::v-deep .v-picker__actions {
  flex-direction: column;

  .buttons {
    width: 100%;
    text-align: right;

    .v-btn {
      margin-left: 8px;
    }
  }
}
</style>
