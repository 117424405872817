<template>
  <div>
    <div>
      <v-navigation-drawer
        :class="!this.$vuetify.theme.dark ? 'grey lighten-4' : ''"
        v-if="showDrawerLeft"
        v-model="drawer"
        mobile-breakpoint="768"
        clipped
        :width="$vuetify.breakpoint.lgAndDown ? 300 : 360"
        disable-route-watcher
        :absolute="absolute"
        :app="app"
      >
        <div class="d-flex justify-space-between">
          <navigation-menu class="flex-grow-1 text-truncate"/>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <div>
              <v-container
                class="mt-n1 drawer-left pr-1"
              >
                <v-row>
                  <v-col>
                    <div>
                      <v-btn
                        fab
                        small
                        @click.stop="drawer = !drawer"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </template>

        </div>

        <div
          :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
        >
          <slot name="drawer-left" />
        </div>
      </v-navigation-drawer>

      <v-app-bar
        app
        clipped-right
        clipped-left
        extension-height="0"
      >
        <v-app-bar-nav-icon
          v-if="backButton"
          @click.stop="goBack()"
          :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : 'mr-0'"
        >
          <v-icon size="28">
            mdi-arrow-left
          </v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon
          v-if="showDrawerLeft"
          @click.stop="drawer = !drawer"
          class="d-md-none mr-2"
        >
          <v-icon
            v-if="filterButton"
            class="d-md-none"
          >
            mdi-tune
          </v-icon>
          <v-icon
            v-if="bookmarkButton"
            class="d-md-none"
          >
            mdi-bookmark-multiple
          </v-icon>
        </v-app-bar-nav-icon>
        <div
          class="d-none px-0 d-md-block"
          style="width: 312px !important;"
        >
          <v-toolbar-title>
            <slot name="identity">
              <a
                href="/"
                class="d-flex align-start"
              >
                <img
                  style="cursor: pointer;"
                  src="../assets/logo-transparant-groot.png"
                  alt="Polpo - Politieke monitoring"
                  height="42"
                >
              </a>
            </slot>
          </v-toolbar-title>
        </div>
        <slot name="searchbar">
          <v-spacer class="d-flex px-0" />
        </slot>

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          icon
          @click="goTawk"
        >
          <v-icon>
            mdi-chat-outline
          </v-icon>
        </v-btn>

        <v-btn
          v-if="!isIE11"
          :icon="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          class="ak-trigger ml-sm-1"
        >
          <v-icon v-if="$vuetify.breakpoint.smAndDown">
            mdi-bell-outline
          </v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Nieuws</span>
          <AnnounceKit
            catch-click=".ak-trigger"
            widget="https://announcekit.app/widgets/v2/nTBvO"
          />
        </v-btn>

        <nav-account />

        <v-app-bar-nav-icon
          v-if="showDrawerRight"
          @click.stop="drawerRight = !drawerRight"
          class="d-lg-none"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-app-bar-nav-icon>

        <appbar-progress-bar
          class="ml-n4"
          style="width: 100vw;"
          slot="extension"
        />
      </v-app-bar>

      <div
        id="main-content"
        class="mt-1"
        style="margin-left: -2px"
      >
        <slot name="main-content" />
        <message-bar />
      </div>

      <v-navigation-drawer
        :class="!this.$vuetify.theme.dark ? 'grey lighten-4' : ''"
        v-if="showDrawerRight"
        v-model="drawerRight"
        mobile-breakpoint="960"
        :width="$vuetify.breakpoint.lgAndDown ? 256 : 320"
        clipped
        right
        disable-route-watcher
        :absolute="absolute"
        :app="app"
      >
        <div>
          <template v-if="$vuetify.breakpoint.mdAndDown && drawerRight">
            <v-container
              class="mt-n1 pr-4 drawer-right"
              style="position: absolute; z-index: 20000;"
            >
              <v-row>
                <v-col>
                  <div
                    class="d-flex justify-end"
                  >
                    <v-btn
                      fab
                      small
                      @click.stop="drawerRight = !drawerRight"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </div>
        <div
          class="mt-2"
          :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
        >
          <slot name="drawer-right" />
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
import AnnounceKit from 'announcekit-vue';
import NavigationMenu from '@/components/NavigationMenu.vue';
import MessageBar from '@/components/TheMessageBar.vue';
import NavAccount from '@/components/TheNavAccount.vue';
import AppbarProgressBar from '@/components/AppbarProgressBar.vue';

export default {

  components: {
    NavigationMenu,
    MessageBar,
    NavAccount,
    AppbarProgressBar,
    AnnounceKit,
  },

  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    app: {
      type: Boolean,
      required: false,
      default: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    bookmarkButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDrawerLeft: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDrawerRight: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    // isOpenTidio: false,
    drawer: null,
    drawerRight: null,
  }),

  methods: {
    goBack() {
      if (this.referrer === false) {
        if (this.$route.name === 'eu-document') {
          this.$store.dispatch('setReferrer', true);
          this.$router.push({ path: '/eu/dashboard' });
        } else {
          this.$store.dispatch('setReferrer', true);
          this.$router.push({ path: '/dashboard' });
        }
      } else {
        this.$router.go(-1);
      }
    },
    goHome() {
      this.$router.push('/');
    },
    goTawk() {
      window.open('https://monitor.polpo.nl/chat', '_blank');
    },
  },

  computed: {
    ...mapGetters({
      userPreferences: 'userPreferences',
      isIE11: 'isIE11',
      referrer: 'referrer',
    }),

    isSmallerScreenSizeDevive() {
      if (window.matchMedia('(max-width: 1440px)').matches) {
        return true;
      }
      return false;
    },
  },

};
</script>

<style lang="scss">
  // We want to suppress certain styles explicitly on IE because it sucks!
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
    .drawer-left, .drawer-right {
      display: none;
    }
  }

  .v-appbar__extension {
    padding: 0px !important;
  }

</style>
