<template>
  <div>
    <v-card class="mb-4 mt-5">
      <v-card-title class="text-subtitle-1">
        Exporteer zoekopdrachten
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Download al jouw zoekopdrachten als PDF
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-btn
              width="100%"
              @click="generateNLSavedSearchesExport"
            >
              NL zoekopdrachten
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasEuModuleAccessAndEuSavedSearches"
              width="100%"
              @click="generateEUSavedSearchesExport"
            >
              EU zoekopdrachten
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="hasDlrcModuleAccessAndDlrcSavedSearches"
              width="100%"
              @click="generateDlrcSavedSearchesExport"
            >
              Gem/Prov zoekopdrachten
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="htmlToPdfOptions"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
  >
      <section
        @domRendered="domRendered()"
        slot="pdf-content"
      >
          <!-- PDF Content Here -->
          <saved-searches-as-pdf
            v-if="searches && category"
            :searches="searches"
            :category="category"
            :dashboardName="dashboardName"
            :url="url"
          />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import SavedSearchesAsPdf from '@/components/SavedSearchesAsPdf.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountPreferencesExport',

  components: {
    VueHtml2pdf,
    SavedSearchesAsPdf,
  },

  data() {
    return {
      searches: null,
      category: null,
      dashboardName: null,
      url: null,
    };
  },

  computed: {
    ...mapGetters({
      username: 'userName',
      authHasModuleAccess: 'authHasModuleAccess',
      allSavedSearches: 'allSavedSearches',
      europarlAllSavedSearches: 'europarlAllSavedSearches',
      localRegionalAllSavedSearches: 'localRegionalAllSavedSearches',
    }),

    hasEuModuleAccessAndEuSavedSearches() {
      if (this.authHasModuleAccess('MC04') && this.europarlAllSavedSearches) {
        return true;
      }
      return false;
    },

    hasDlrcModuleAccessAndDlrcSavedSearches() {
      if (this.authHasModuleAccess('MC13') && this.localRegionalAllSavedSearches) {
        return true;
      }
      return false;
    },

    htmlToPdfOptions() {
      return {
        margin: 1,
        image: {
          type: 'jpeg',
          quality: 0.99,
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      };
    },
  },

  methods: {
    generateNLSavedSearchesExport() {
      this.searches = this.allSavedSearches;
      this.category = 'document';
      this.dashboardName = 'NL';
      this.url = '/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateEUSavedSearchesExport() {
      this.searches = this.europarlAllSavedSearches;
      this.category = 'europarl';
      this.dashboardName = 'EU';
      this.url = '/eu/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    generateDlrcSavedSearchesExport() {
      this.searches = this.localRegionalAllSavedSearches;
      this.category = 'nlLr';
      this.dashboardName = 'Gem/Prov';
      this.url = '/nl-lr/dashboard';
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options }) {
      // For some reason we have to set the filename here.
      // If the filename is set in the htmlToPdfOptions the category part is somehow not reactive.
      options.filename = `${this.dashboardName} Zoekopdrachten van ${this.username}`;
      await html2pdf().set(options);
    },

  },
};
</script>

<style lang="scss">

</style>
